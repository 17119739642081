<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 text-left">
          {{new Date().getFullYear()}} &copy; ArgusBlack
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">
            v{{version}} es mantenido<i class="mdi mdi-heart text-danger"></i> por <a href="https://livaur.com/" target="_blank">Livaur</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { version } from '../../package'
export default {
  data() {
    return {
      version: version
    };
  },
}
</script>
<template>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu">
      <li class="menu-title" data-key="t-menu-isp">Menu ISP</li>
      <li v-show="tienePermiso('modulos', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'modulos' }">
          <eva-icon class="icon nav-icon" name="grid-outline"></eva-icon>
          <span class="menu-item" data-key="t-calendar">Modulos</span>
        </router-link>
      </li>

      <li v-show="tienePermiso('inicio', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'inicio' }">
          <eva-icon class="icon nav-icon" name="home-outline"></eva-icon>
          <span class="menu-item" data-key="t-inicio">Inicio</span>
        </router-link>
      </li>

      <li v-show="tienePermiso('listadoClientes', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'listadoClientes' }">
          <eva-icon class="icon nav-icon" name="people-outline"></eva-icon>
          <span class="menu-item" data-key="t-clientes">Clientes</span>
        </router-link>
      </li>

      <li v-show="tienePermiso('calendario', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'calendario' }">
          <eva-icon class="icon nav-icon" name="calendar-outline"></eva-icon>
          <span class="menu-item" data-key="t-soporte">Calendario</span>
        </router-link>
      </li>

      <li 
        class="menu-title" data-key="t-facturas"
        v-show="
          tienePermiso('listadoFacturas',         'vista') ||
          tienePermiso('seleccionClienteVenta',   'vista') ||
          tienePermiso('pagos',                   'vista')
        "
      >Facturas</li>

      <li v-show="tienePermiso('listadoFacturas', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'listadoFacturas' }">
          <eva-icon class="icon nav-icon" name="file-text-outline"></eva-icon>
          <span class="menu-item" data-key="t-list-facturas">Facturas</span>
        </router-link>
      </li>

      <li v-show="tienePermiso('seleccionClienteVenta', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'seleccionClienteVenta' }">
          <eva-icon class="icon nav-icon" name="credit-card-outline"></eva-icon>
          <span class="menu-item" data-key="t-ventas-rapidas">Ventas rápidas</span>
        </router-link>
      </li>

      <li v-show="tienePermiso('pagos', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'pagos' }">
          <eva-icon class="icon nav-icon" name="credit-card"></eva-icon>
          <span class="menu-item" data-key="t-pagos">Pagos</span>
        </router-link>
      </li>

      <li
        class="menu-title" data-key="t-network"
        v-show="
          tienePermiso('listadoCpes',            'vista') ||
          tienePermiso('listadoPlanesServicio',  'vista') ||
          tienePermiso('listadoRouters',         'vista') ||
          tienePermiso('listadoSitios',          'vista') ||
          tienePermiso('listadoAps',             'vista') ||
          tienePermiso('listadoDhs',             'vista')
        "
      >Network</li>

      <li
        v-show="
          tienePermiso('listadoCpes',            'vista') ||
          tienePermiso('listadoPlanesServicio',  'vista') ||
          tienePermiso('listadoRouters',         'vista') ||
          tienePermiso('listadoSitios',          'vista') ||
          tienePermiso('listadoAps',             'vista') ||
          tienePermiso('listadoDhs',             'vista')
        "
      >
        <a href="javascript: void(0);" class="has-arrow">
          <eva-icon class="icon nav-icon" name="shake-outline"></eva-icon>
          <span class="menu-item" data-key="t-menu-network">Network</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li v-show="tienePermiso('estadoRed', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'estadoRed' }" data-key="t-estado-red">Estado de red</router-link>
          </li>
          <li v-show="tienePermiso('listadoCpes', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'listadoCpes' }" data-key="t-cpes">CPEs</router-link>
          </li>
          <li v-show="tienePermiso('mapaCpes', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'mapaCpes' }" data-key="t-mapa">Mapa</router-link>
          </li>
          <li v-show="tienePermiso('listadoPlanesServicio', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'listadoPlanesServicio' }" data-key="t-planes-servicio">Planes de servicio</router-link>
          </li>
          <li v-show="tienePermiso('listadoRouters', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'listadoRouters' }" data-key="t-routers-administracion">Routers de administración</router-link>
          </li>
          <li v-show="tienePermiso('listadoSitios', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'listadoSitios' }" data-key="t-sitios">Sitios</router-link>
          </li>
          <li v-show="tienePermiso('listadoAps', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'listadoAps' }" data-key="t-aps">Puntos de acceso</router-link>
          </li>
          <li v-show="tienePermiso('listadoDhs', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'listadoDhs' }" data-key="t-dispositivos-hogar">Dispositivos de hogar</router-link>
          </li>
          <li v-show="tienePermiso('listadoNocs', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'listadoNocs' }" data-key="t-nocs" title="Centros de Operación de Red">NOC</router-link>
          </li>
        </ul>
      </li>

      <li
        class="menu-title" data-key="t-produc"
        v-show="
          tienePermiso('listadoProductos',                'vista') ||
          tienePermiso('listadoSolicitudesInstalacion',   'vista') ||
          tienePermiso('listadoTicketsSoporte',           'vista') ||
          tienePermiso('listadoReporteClientesSitio',     'vista')
        "
      >Productos</li>

      <li v-show="tienePermiso('listadoProductos', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'listadoProductos' }">
          <eva-icon class="icon nav-icon" name="pricetags-outline"></eva-icon>
          <span class="menu-item" data-key="t-productos">Productos y servicios</span>
        </router-link>
      </li>

      <li v-show="tienePermiso('listadoSolicitudesInstalacion', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'listadoSolicitudesInstalacion' }">
          <eva-icon class="icon nav-icon" name="archive-outline"></eva-icon>
          <span class="menu-item" data-key="t-instalaciones">Instalaciones</span>
        </router-link>
      </li>

      <li v-show="tienePermiso('listadoTicketsSoporte', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'listadoTicketsSoporte' }">
          <eva-icon class="icon nav-icon" name="clipboard-outline"></eva-icon>
          <span class="menu-item" data-key="t-soporte">Soportes</span>
        </router-link>
      </li>

      <li v-show="tienePermiso('listadoReporteClientesSitio', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ path: '/reportes' }">
          <eva-icon class="icon nav-icon" name="book-outline"></eva-icon>
          <span class="menu-item" data-key="t-reporte">Reportes</span>
        </router-link>
      </li>

      <li
        class="menu-title" data-key="t-sistem"
        v-show="
          tienePermiso('listadoUsuarios',  'vista') ||
          tienePermiso('ajustesSistema',    'vista') ||
          tienePermiso('sistemaCuenta',    'vista') ||
          tienePermiso('acercaDe',         'vista')
        "
      >Sistema</li>

      <li v-show="
          tienePermiso('listadoUsuarios',  'vista') ||
          tienePermiso('ajustesSistema',    'vista') ||
          tienePermiso('sistemaCuenta',    'vista') ||
          tienePermiso('acercaDe',         'vista')
        "
      >
        <a href="javascript: void(0);" class="has-arrow">
          <eva-icon class="icon nav-icon" name="settings-2-outline"></eva-icon>
          <span class="menu-item" data-key="t-sistema">Sistema</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li v-show="tienePermiso('listadoUsuarios', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'listadoUsuarios' }" data-key="t-usuarios">
              Usuarios
            </router-link>
          </li>

          <li v-show="tienePermiso('ajustesSistema', 'vista')">
            <a href="javascript: void(0);" class="has-arrow">
              <span data-key="t-ajustes">Ajustes</span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <li v-show="tienePermiso('ajustesGeneralesSistema', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesGeneralesSistema' }"
                  data-key="t-ajustes-general"
                >
                  General
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesClientesSistema', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesClientesSistema' }"
                  data-key="t-ajustes-general"
                >
                  Clientes
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesFacturacionSistema', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesFacturacionSistema' }"
                  data-key="t-ajustes-facturacion"
                >
                  Facturación
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesInventarioSistema', 'vista')">
                <router-link class="side-nav-link-ref" :to="{ name: 'ajustesInventarioSistema' }" data-key="t-ajustes-inventario">Inventario</router-link>
              </li>
              <li v-show="tienePermiso('ajustesTicketPagoSistema', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesTicketPagoSistema' }"
                  data-key="t-ajustes-ticket-pago"
                >
                  Ticket de pago
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesPasarelasPago', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesPasarelasPago' }"
                  data-key="t-ajustes-pasarelas-pago"
                >
                  Pasarelas de pago
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesPlantillaNotificacionPorCorreoSistema', 'vista')">
                <router-link 
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesPlantillaNotificacionPorCorreoSistema' }" 
                  data-key="t-ajustes-plantilla-notificacion-correo"
                >
                  Plantilla de notificación por correo
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesPlantillaServicioSuspendidoSistema', 'vista')">
                <router-link 
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesPlantillaServicioSuspendidoSistema' }"
                  data-key="t-ajustes-plantilla-servicio-suspendido"
                >
                  Plantilla de servicio suspendido
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesNetwork', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesNetwork' }"
                  data-key="t-ajustes-network"
                >
                  Network
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesDatabase', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesDatabase' }"
                  data-key="t-ajustes-database"
                >
                  Database backup
                </router-link>
              </li>
            </ul>
          </li>

          <li v-show="tienePermiso('ajustesSistema', 'vista')">
            <a href="javascript: void(0);" class="has-arrow">
              <span data-key="t-ajustes">
                <small>miserviciodeinternet.com</small>
              </span>
            </a>
            <ul class="sub-menu" aria-expanded="true">
              <li v-show="tienePermiso('ajustesGeneralesSistemaMSI', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesGeneralesSistemaMSI' }"
                  data-key="t-ajustes-general-sistema-msi"
                >
                  General
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesPasarelasPagoSistema', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesPasarelasPagoSistema' }"
                  data-key="t-ajustes-pasarelas-pago-sistema-msi"
                >
                  Pasarelas de pago
                </router-link>
              </li>
              <li v-show="tienePermiso('ajustesMediosPagoPanelClienteSistema', 'vista')">
                <router-link
                  class="side-nav-link-ref"
                  :to="{ name: 'ajustesMediosPagoPanelClienteSistema' }"
                  data-key="t-ajustes-medios-pago-panel-cliente-sistema-msi"
                >
                  Medios de pago para el panel del cliente
                </router-link>
              </li>
            </ul>
          </li>

          <li v-show="tienePermiso('sistemaCuenta', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'sistemaCuenta' }" data-key="t-cuenta">
              Cuenta
            </router-link>
          </li>
          <li v-show="tienePermiso('acercaDe', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'acercaDe' }" data-key="t-acerca-de">
              Acerca de
            </router-link>
          </li>
          <li id="instalador" style="display: none;">
            <a href="javascript: void(0);" class="side-nav-link-ref" @click="instaladorArgus()">
              <span data-key="t-instalar-argusblack">Instalar ArgusBlack</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>

<script>
export default {
  name: 'NavIsp',
  props: {
    tienePermiso: {
      type: Function,
      requerid: true
    }
  },
  mounted: function() {
    if(window.deferredPrompt != null) {
      let instalador = document.getElementById('instalador')
      instalador.style.display = 'block'
    }
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      let instalador = document.getElementById('instalador')
      instalador.style.display = 'block'
      window.deferredPrompt = e
    })
  },

  methods: {
    instaladorArgus:function() {
      if (window.deferredPrompt !== null) {
        window.deferredPrompt.prompt()
        const { outcome } = window.deferredPrompt.userChoice
        if (outcome === 'accepted') {
          window.deferredPrompt = null
        }
      } else {
        iu.msg.warning("Debe de recargar la vista para cargar el instalador correctamente")
      }
    }
  }
}
</script>

<style scoped>
</style>

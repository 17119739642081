<template>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu">
      <li class="menu-title" data-key="t-menu">Menu</li>
      <li v-show="tienePermiso('modulos', 'vista')">
        <router-link class="side-nav-link-ref" :to="{ name: 'modulos' }">
          <eva-icon class="icon nav-icon" name="grid-outline"></eva-icon>
          <span class="menu-item" data-key="t-modulos">Modulos</span>
        </router-link>
      </li>

      <li class="menu-title" data-key="t-sistem">Sistema</li>

      <li v-show="
          tienePermiso('listadoUsuarios',  'vista') ||
          tienePermiso('ajustesSistema',   'vista') ||
          tienePermiso('acercaDe',         'vista')
        "
      >
        <a href="javascript: void(0);" class="has-arrow">
          <eva-icon class="icon nav-icon" name="settings-2-outline"></eva-icon>
          <span class="menu-item" data-key="t-sistema">Sistema</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li v-show="$store.state.todo.susargus && tienePermiso('listadoUsuarios', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'listadoUsuarios' }" data-key="t-usuarios">
              Usuarios
            </router-link>
          </li>
          <li v-show="tienePermiso('sistemaCuenta', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'sistemaCuenta' }" data-key="t-cuenta">
              Cuenta
            </router-link>
          </li>
          <li v-show="$store.state.todo.susargus && tienePermiso('acercaDe', 'vista')">
            <router-link class="side-nav-link-ref" :to="{ name: 'acercaDe' }" data-key="t-acerca-de">
              Acerca de
            </router-link>
          </li>
          <li id="instalador" style="display: none;">
            <a href="javascript: void(0);" class="side-nav-link-ref" @click="instaladorArgus()">
              <span data-key="t-instalar-argusblack">Instalar ArgusBlack</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>

<script>
export default {
  name: 'Nav',
  props: {
    tienePermiso: {
      type: Function,
      requerid: true
    }
  },
  mounted: function() {
    if(window.deferredPrompt != null) {
      let instalador = document.getElementById('instalador')
      instalador.style.display = 'block'
    }
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      let instalador = document.getElementById('instalador')
      instalador.style.display = 'block'
      window.deferredPrompt = e
    })
  },
  methods: {
    instaladorArgus:function() {
      if (window.deferredPrompt !== null) {
        window.deferredPrompt.prompt()
        const { outcome } = window.deferredPrompt.userChoice
        if (outcome === 'accepted') {
          window.deferredPrompt = null
        }
      } else {
        iu.msg.warning("Debe de recargar la vista para cargar el instalador correctamente")
      }
    }
  }
}
</script>

<style scoped>
</style>
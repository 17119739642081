<template>
  <div>
    <!-- Right Sidebar -->
    <div v-click-outside="config" class="right-bar">
      <SimpleBar class="h-100">
        <div class="rightbar-title d-flex align-items-center bg-dark p-3">
          <h5 class="m-0 me-2 text-white">Personalizador de tema</h5>
          <a href="javascript:void(0);" class="right-bar-toggle-close ms-auto" @click="hide()">
            <i class="mdi mdi-close noti-icon"></i>
          </a>
        </div>
        <div class="p-3">
          <h6 class="mt-2 mb-3">Modo</h6>
          <hr class="mt-1"/>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="mode"
              id="modo-light"
              value="light"
              v-model="mode"
            />
            <label class="form-check-label" for="modo-light">
              Light
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="mode"
              id="modo-dark"
              value="dark"
              v-model="mode"
            />
            <label class="form-check-label" for="modo-dark">
              Dark
            </label>
          </div>
          <h6 class="mt-4 mb-3">Posición del Layout</h6>
          <hr class="mt-1" />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              v-model="scrollable"
              type="radio"
              name="layout-position"
              id="layout-position-fixed"
              :value="false"
            />
            <label class="form-check-label" for="layout-position-fixed">
              Fija
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              v-model="scrollable"
              type="radio"
              name="layout-position"
              id="layout-position-scrollable"
              :value="true"
            />
            <label class="form-check-label" for="layout-position-scrollable">
              Desplazable
            </label>
          </div>
          <h6 class="mt-4 mb-3">Color de la barra superior</h6>
          <hr class="mt-1" />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              v-model="topbar"
              type="radio"
              name="topbar-color"
              id="topbar-color-light"
              value="light"
            />
            <label class="form-check-label" for="topbar-color-light">
              Light
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              v-model="topbar"
              type="radio"
              name="topbar-color"
              id="topbar-color-dark"
              value="dark"
            />
            <label class="form-check-label" for="topbar-color-dark">
              Dark
            </label>
          </div>
          <!-- Sidebar -->
          <div v-if="layout === 'vertical'">
            <h6 class="mt-3">Color de la barra lateral</h6>
            <hr class="mt-1" />
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="sidebar-color"
                id="sidebar-color-dark"
                value="dark"
                v-model="color"
              />
              <label class="form-check-label" for="sidebar-color-dark">
                Dark
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="sidebar-color"
                id="sidebar-color-light"
                value="light"
                v-model="color"
              />
              <label class="form-check-label" for="sidebar-color-light">
                Light
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="sidebar-color"
                id="sidebar-color-brand"
                value="brand"
                v-model="color"
              />
              <label class="form-check-label" for="sidebar-color-brand">
                Brand
              </label>
            </div>
            <h6 class="mt-3">Tipo de barra lateral</h6>
            <hr class="mt-1" />
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="sidebar-type"
                id="sidebar-type-default"
                value="default"
                v-model="sidebarType"
              />
              <label class="form-check-label" for="sidebar-type-default">
                Default
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="sidebar-type"
                id="sidebar-type-compact"
                value="compact"
                v-model="sidebarType"
              />
              <label class="form-check-label" for="sidebar-type-compact">
                Compact
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="sidebar-type"
                id="sidebar-type-icon"
                value="icon"
                v-model="sidebarType"
              />
              <label class="form-check-label" for="sidebar-type-icon">
                Icon
              </label>
            </div>
          </div>
          <!-- Topbar -->
        </div>
      </SimpleBar>
      <!-- end slimscroll-menu-->
    </div>
    <!-- /Right-bar -->
  </div>
</template>

<script>
import { layoutMethods, layoutComputed } from "@/state/helpers"
import { SimpleBar } from "simplebar-vue3"
import UsuarioSrv from '@/services/UsuarioSrv.js'

/**
 * Right sidebar component
 */
export default {
  components: {
    SimpleBar,
  },
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"]
      }
    }
  },
  methods: {
    ...layoutMethods,
    hide() {
      this.$parent.toggleRightSidebar()
    },
    handleRightBarClick() {
      this.$parent.hideRightSidebar()
    },
    middleware(event) {
      return !event.target.classList.contains("toggle-right")
    },
    changeLayout(layout) {
      this.changeLayoutType({ layoutType: layout })
    },
    changeType(type) {
      this.changeLayoutType({ layoutType: "vertical" })
      return this.changeLeftSidebarType({ leftSidebarType: type })
    },
    changeWidth(width) {
      return this.changeLayoutWidth({ layoutWidth: width })
    },
    changeTopbartype(value) {
      return this.changeTopbar({ topbar: value })
    },
    changeloader() {
      return this.changeLoaderValue({ loader: this.loader })
    },
    /**
     * Change sidebar color change
     */
    sidebarColorChange(color) {
      return this.changeSidebarColor({ sidebarColor: color })
    },
    /**
     * Change layout position
     */
    changePosition(value) {
      return this.changeLayoutPosition({ layoutScrollable: value })
    }
  },
  computed: {
    ...layoutComputed,
    loader: {
      get() {
        return this.$store ? this.$store.state.layout.loader : {} || {}
      },
      set(value) {
        this.changeLoaderValue({ loader: value })
      }
    },
    color: {
      get() {
        let storage = localStorage.getItem("argusblack.theme.color") != null ? localStorage.getItem("argusblack.theme.color") : null
        let value = storage != null ? storage : ( this.$store ? this.$store.state.layout.sidebarColor : {} || {} )
        this.changeSidebarColor({ sidebarColor: value })
        return value
      },
      set(value) {
        UsuarioSrv.actualizarAjustes({color: value}).then(response => {
          localStorage.setItem("argusblack.theme.color", value)
          return this.changeSidebarColor({ sidebarColor: value })
        }).catch(error => {
          let mensaje 
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar tema de color'
          }
          console.log(error)
          iu.msg.error(mensaje)
        })
      }
    },
    sidebarType: {
      get() {
        let storage = localStorage.getItem("argusblack.theme.sidebarType") != null ? localStorage.getItem("argusblack.theme.sidebarType") : null
        let value = storage != null ? storage : ( this.$store ? this.$store.state.layout.leftSidebarType : {} || {} )
        this.changeLeftSidebarType({ leftSidebarType: value })
        return value
      },
      set(value) {
        UsuarioSrv.actualizarAjustes({sidebarType: value}).then(response => {
          localStorage.setItem("argusblack.theme.sidebarType", value)
          return this.changeLeftSidebarType({ leftSidebarType: value })
        }).catch(error => {
          let mensaje 
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar tema de sidebarType'
          }
          console.log(error)
          iu.msg.error(mensaje)
        })
      }
    },
    scrollable: {
      get() {
        let storage = localStorage.getItem("argusblack.theme.layoutScrollable") != null ? (localStorage.getItem("argusblack.theme.layoutScrollable") == 'true' ? true : false) : null
        let value = storage != null ? storage : ( this.$store ? this.$store.state.layout.layoutScrollable : {} || {} )
        this.changeLayoutPosition({ layoutScrollable: value })
        return value
      },
      set(value) {
        UsuarioSrv.actualizarAjustes({layoutScrollable: value}).then(response => {
          localStorage.setItem("argusblack.theme.layoutScrollable", value)
          return this.changeLayoutPosition({ layoutScrollable: value })
        }).catch(error => {
          let mensaje 
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar tema de layoutScrollable'
          }
          console.log(error)
          iu.msg.error(mensaje)
        })
      }
    },
    layout: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {}
      },
      set(value) {
        this.changeLayoutType({ layoutType: value, })
        if (value === "horizontal") {
          console.log("horizontal");
          this.changeTopbar({ topbar: "dark" })
        } else if (value === "vertical") {
          this.changeTopbar({ topbar: "light" })
        }
      }
    },
    topbar: {
      get() {
        let storage = localStorage.getItem("argusblack.theme.topbar") != null ? localStorage.getItem("argusblack.theme.topbar") : null
        let value = storage != null ? storage : (this.$store ? this.$store.state.layout.topbar : {} || {})
        this.changeTopbar({ topbar: value })
        return value
      },
      set(value) {
        UsuarioSrv.actualizarAjustes({topbar: value}).then(response => {
          localStorage.setItem("argusblack.theme.topbar", value)
          this.changeTopbar({ topbar: value })
        }).catch(error => {
          let mensaje 
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar tema de topbar'
          }
          console.log(error)
          iu.msg.error(mensaje)
        })
      }
    },
    mode: {
      get() {
        let storage = localStorage.getItem("argusblack.theme.mode") != null ? localStorage.getItem("argusblack.theme.mode") : null
        return storage != null ? storage : ( this.$store ? this.$store.state.layout.layoutMode : {} || {} )
      },
      set(value) {
        if (value === "dark") {
          UsuarioSrv.actualizarAjustes({mode: value, topbar:'dark', color:'dark'}).then(response => {
            document.body.setAttribute("data-layout-mode", "dark")
            this.changeLayoutMode({ layoutMode: value })
            this.changeTopbar({ topbar: "dark" })
            this.changeSidebarColor({ sidebarColor: "dark" })

            localStorage.setItem("argusblack.theme.mode", value)
            localStorage.setItem("argusblack.theme.topbar", 'dark')
            localStorage.setItem("argusblack.theme.color", 'dark')
          }).catch(error => {
            let mensaje 
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo guardar tema de topbar'
            }
            console.log(error)
            iu.msg.error(mensaje)
          })
        } else {
          UsuarioSrv.actualizarAjustes({mode: value, topbar:'light', color:'dark'}).then(response => {
            document.body.setAttribute("data-layout-mode", "light")
            this.changeLayoutMode({ layoutMode: value })
            this.changeTopbar({ topbar: "light" })
            this.changeSidebarColor({ sidebarColor: "dark" })
            localStorage.setItem("argusblack.theme.mode", value)
            localStorage.setItem("argusblack.theme.topbar", 'light')
            localStorage.setItem("argusblack.theme.color", 'dark')
          }).catch(error => {
            let mensaje 
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo guardar tema de topbar'
            }
            console.log(error)
            iu.msg.error(mensaje)
          })
        }
      }
    },
    width: {
      get() {
        return this.$store ? this.$store.state.layout.layoutWidth : {} || {}
      },
      set(value) {
        this.changeLayoutWidth({ layoutWidth: value })
      }
    }
  },
  watch: {
    mode: {
      immediate: true,
      handler: function (newVal) {
        switch (newVal) {
          case "light":
            document.body.setAttribute("data-layout-mode", "light")
          break
          case "dark":
            document.body.setAttribute("data-layout-mode", "dark")
          break
        }
      }
    },

    topbar: {
      immediate: true,
      handler: function (newVal) {
        switch (newVal) {
          case "light":
            document.body.setAttribute("data-topbar", "light")
          break
          case "dark":
            document.body.setAttribute("data-topbar", "dark")
          break
        }
      }
    },

    sidebarType: {
      immediate: true,
      handler: function(newVal) {
        switch (newVal) {
          case "default":
            document.body.setAttribute("data-sidebar-size", "lg")
            localStorage.setItem("argusblack.menu.data-sidebar-size", "sm")
          break;
          case "compact":
            document.body.setAttribute("data-sidebar-size", "md")
            localStorage.setItem("argusblack.menu.data-sidebar-size", "md")
          break;
          case "icon":
            document.body.setAttribute("data-sidebar-size", "sm")
            localStorage.setItem("argusblack.menu.data-sidebar-size", "lg")
          break
        }
      }
    }
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>
<template>
  <header id="page-topbar" class="isvertical-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <button
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn topnav-hamburger"
          @click="toggleMenu"
        >
          <span class="d-block d-sm-none">
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </span>
          <span class="d-none d-sm-block">
            <span class="hamburger-icon open">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </span>
        </button>
        <div class="d-none d-sm-block ms-3 align-self-center">
          <span class="page-title h4 text-truncate w-100" :class="modoTopbar == 'light' ? 'text-dark' : 'text-white'" v-if="tituloPagina">
            {{tituloPagina}}
          </span>
          <span class="page-title h4" :style="modoTopbar == 'light' ? '' : 'color: #B1BCC7'" v-if="breadcrumb">
            <template v-for="(bread, index) in breadcrumb">
              <span v-if="bread.ruta == null" :key="index">{{ bread.titulo }}</span>
              <router-link 
                :class="modoTopbar == 'light' ? 'text-dark' : 'text-white'"
                v-if="bread.ruta != null" :to="{ name: bread.ruta, params: bread.params }"
                :key="'link-'+index"
              >
                {{ bread.titulo }}
              </router-link>
              {{ index != breadcrumb.length-1 ? ' | ' : ''}}
            </template>
          </span>
        </div>
      </div>
      <div class="d-flex">
        <div class="dropdown d-inline-block d-flex align-items-center" v-if="visibleModo">
          <span
            class="badge rounded-pill bg-gradient font-size-15 px-3 me-2"
            :class="{ 
              'bg-warning': modoDes == 'development',
              'bg-info': modoDes == 'testing',
              'bg-success': modoDes == 'production'
            }"
          >
            {{ modoDes == 'development'? 'Dev':'' }}
            {{ modoDes == 'testing'? 'Test':'' }}
            {{ modoDes == 'production'? 'Prod':'' }}
          </span>
        </div>
        <div class="dropdown d-inline-block d-flex align-items-center">
          <span
            class="d-none d-sm-block"
            :style="modoTopbar == 'light' ? '' : 'color: #B1BCC7'"
          >
            {{ nombreEmpresa }}
          </span>
        </div>
        <!-- Notificaciones -->
        <campana></campana>
        <!-- fin de notificaciones -->
        <div class="dropdown d-inline-block">
          <!-- <router-link
            class="btn header-item noti-icon d-flex align-items-center"
            :to="{ name: 'soporteParaArgusBlack' }"
            title="¿Tienes dudas o requieres soporte?"
          >
            <eva-icon 
              class="icon-sm toggle-right"
              :style="modoTopbar == 'light' ? '' : 'color: #B1BCC7'"
              name="question-mark-circle-outline"
            ></eva-icon>
          </router-link> -->
          <a
            class="btn header-item noti-icon d-flex align-items-center"
            href="https://wa.me/5215662202397"
            title="¿Tienes dudas o requieres soporte?"
            target="_blank"
          >
            <eva-icon 
              class="icon-sm toggle-right"
              :style="modoTopbar == 'light' ? '' : 'color: #B1BCC7'"
              name="question-mark-circle-outline"
            ></eva-icon>
          </a>
        </div>
        <div class="dropdown d-inline-block">
          <a
            href="https://documentacion.argus.black/"
            target="_blank"
            title="Documentación ArgusBlack"
            class="btn header-item noti-icon d-flex align-items-center"
          >
            <eva-icon
              :style="modoTopbar == 'light' ? '' : 'color: #B1BCC7'"
              class="icon-sm toggle-right" name="book"
            ></eva-icon>
          </a>
        </div>
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item user text-start d-flex align-items-center"
            id="page-header-user-dropdown-v"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              alt="FOTO"
              class="rounded-circle header-profile-user"
              :src="API +'/usuarios/actual/foto?&tipo_foto=128x128&_tk='+tk+'&rnd='+rnd"
              v-if="usuario.id_foto != null"
            />
            <img
              alt="FOTO"
              :src="usuarioicon"
              class="rounded-circle header-profile-user"
              v-if="usuario.id_foto == null"
            />
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-0">
            <div class="p-3 border-bottom">
              <h6 class="mb-0">
                {{usuario.nombre}} {{usuario.apellido_paterno}} {{usuario.apellido_materno}}
              </h6>
              <p class="mb-0 font-size-11 text-muted">
                {{usuario.correo}}
              </p>
            </div>
            <router-link class="dropdown-item" :to="{ name: 'perfilUsuarioSesion', params: { id: usuario.id } }">
              <i class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>
              <span class="align-middle">Perfil</span>
            </router-link>
            <button class="dropdown-item" @click="toggleRightSidebar()">
              <i class="mdi mdi-theme-light-dark text-muted font-size-16 align-middle me-1"></i>
              <span class="align-middle">Tema</span>
            </button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" @click="cerrarSesion()">
              <i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>
              <span class="align-middle">Salir</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import usuarioicon from '@/assets/img/usuario2.png'
import SesionSrv from '@/services/SesionSrv.js'
import { todoMethods, todoComputed, layoutMethods } from "@/state/helpers"
import Campana from "./Campana.vue"
import UsuarioSrv from '@/services/UsuarioSrv.js'
import API from '@/API.js'

/**
 * Topbar component
 */
export default {
  components: {
    Campana
  },
  data() {
    return {
      text: null,
      flag: null,
      value: null,
      tk: localStorage.getItem('argusblack.token'),
      API: API,
      baseUrl: window.baseUrl,
      idFoto: null,
      usuarioicon: usuarioicon,
      n_notificaciones: 0
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    layoutscroll: {
      type: Boolean,
      required: true,
    },
    tituloPagina: {
      type: String,
      default: null
    },
    breadcrumb: {
      type: Array
    },
    modoDes: {
      type: String,
      required: true
    },
    visibleModo:{
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...todoComputed,
    nombreEmpresa: function(){
      return this.$store.state.todo.nombre_empresa
    },
    rnd: function() {
      return this.$store.state.todo.rnd
    },
    modoTopbar: function() {
      return this.$store.state.layout.topbar
    },
  },
  methods: {
    ...todoMethods,
    ...layoutMethods,
    cargarIdFotoUsuarioActual () {
      var self = this

      UsuarioSrv.idFotoUsuarioActual().then(response => {
        let id = response.data
        if (id == '') id = null
        self.cargarIdFoto({ idFoto: id })
      })
    },
    cerrarSesion: function() {
      var self = this

      SesionSrv.cerrar()

      self.limpiarUsuario()

      self.$router.push({ name: 'login' })

      // // Cerrar modals abiertos
      // $(document).ready(function(){
      //   $('.modal').modal('hide');
      // })
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      this.$parent.toggleMenu();
      localStorage.setItem("argusblack.theme.sidebarType", 'default')
      this.changeLeftSidebarType({ leftSidebarType: 'default' })
    }
  },
  created () {
    var self = this

    // Se manda a actualizar la foto cuando el usuario actual ha cambiado su foto
    iu.bus.on('fotoUsuarioActualizada', function() {
      self.cargarIdFotoUsuarioActual()
      self.actualizarRandom({ rnd: Math.random() })
    })

    iu.bus.on('actualizarNNotificaciones', function(n) {
      self.n_notificaciones = n
    })
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    layoutscroll: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case false:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
            case true:
              document.body.setAttribute("data-layout-scrollable", "true");
              break;
            default:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
          }
        }
      },
    },
  },
}
</script>
import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/mensajes-para-usuarios`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  cantidad(params){
    params = params || {}
    return apiClient.get('cantidad', {params:params})
  },

  cantidadNoLeidos(params){
    params = params || {}
    return apiClient.get('cantidad-no-leidos', {params:params})
  },
  
  eliminar(idMensaje) {
    return apiClient.delete(`${idMensaje}`)
  },

  eliminarMensajes(params) {
    params = params || {}
    return apiClient.delete('', {params: params})
  },

  guardar(mensaje) {
    return apiClient.post('', mensaje)
  },

  marcarMensajesVistos(params){
    params = params || {}
    return apiClient.patch('vistos', params)
  },

  marcarMensajeVisto(idMensaje){
    return apiClient.patch(`${idMensaje}/visto`)
  },

  marcarMensajesNoVistos(params){
    params = params || {}
    return apiClient.patch('no-vistos', params)
  },

  marcarMensajeNoVisto(idMensaje){
    return apiClient.patch(`${idMensaje}/no-visto`)
  },

  mensajeJSON(idMensaje) {
    return apiClient.get(`${idMensaje}.json`)
  },

  mensajesJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/mensajes-para-usuarios.json`, { params:params })
  }
}
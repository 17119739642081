<template>
  <div>
    <!-- Begin page -->
    <div id="layout-wrapper">
      <Topbar
        :tituloPagina="tituloPagina"
        :type="topbar"
        :layoutscroll="layoutScrollable"
        :modoDes="modoDes"
        :visibleModo="visibleModo"
        :breadcrumb="breadcrumb"
      />

      <SideBar
        :type="leftSidebarType"
        :width="layoutWidth"
        :color="sidebarColor"
      />
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <CardSuscripcionFinalizada v-show="!$store.state.todo.susargus"/>
              </div>
            </div>
            <slot/>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
    <Rightsidebar />
  </div>
</template>

<script>
import router from "@/router"

import Topbar from "@/components/topbar"
import SideBar from "@/components/side-bar"
import Rightsidebar from "@/components/right-sidebar"
import Footer from "@/components/footer"
import { layoutComputed } from "@/state/helpers"

import CardSuscripcionFinalizada from '@/components/CardSuscripcionFinalizada.vue'

/**
 * Vertical component
 */
export default {
  props: {
    tituloPagina: {
      type: String,
      default: null
    },
    breadcrumb: {
      type: Array
    },
    modoDes: {
      type: String,
      required: true
    },
    visibleModo:{
      type: Boolean,
      required: true
    }
  },
  data() {
    return {}
  },
  components: {
    Topbar,
    SideBar,
    Rightsidebar,
    Footer,
    CardSuscripcionFinalizada 
  },
  created: () => {
    document.body.removeAttribute("data-layout")
  },
  mounted() {
    var sidebarSize = localStorage.getItem("argusblack.menu.data-sidebar-size") != null ?
                      localStorage.getItem("argusblack.menu.data-sidebar-size") : null

    if(sidebarSize != null){
      if(sidebarSize == 'lg') document.body.setAttribute("data-sidebar-size", "sm")
      if(sidebarSize == 'md') document.body.setAttribute("data-sidebar-size", "md")
      if(sidebarSize == 'sm') document.body.setAttribute("data-sidebar-size", "lg")
    }
  },
  computed: {
    ...layoutComputed
  },
  methods: {
    toggleMenu() {
      var currentSIdebarSize = document.body.getAttribute("data-sidebar-size")
      document.body.classList.toggle("sidebar-enable")
      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable")
        })
        if (currentSIdebarSize == null) {
          document.body.getAttribute("data-sidebar-size") == null ||
          document.body.getAttribute("data-sidebar-size") == "lg" ?
          document.body.setAttribute("data-sidebar-size", "sm") : 
          document.body.setAttribute("data-sidebar-size", "lg")

          document.body.getAttribute("data-sidebar-size") == null ||
          document.body.getAttribute("data-sidebar-size") == "lg" ?
          localStorage.setItem("argusblack.menu.data-sidebar-size", "sm") :
          localStorage.setItem("argusblack.menu.data-sidebar-size", "lg")
        } else if (currentSIdebarSize == "md") {
          document.body.getAttribute("data-sidebar-size") == "md" ? 
          document.body.setAttribute("data-sidebar-size", "sm") :
          document.body.setAttribute("data-sidebar-size", "md")

          document.body.getAttribute("data-sidebar-size") == "md" ? 
          localStorage.setItem("argusblack.menu.data-sidebar-size", "sm") :
          localStorage.setItem("argusblack.menu.data-sidebar-size", "md")
        } else {
          document.body.getAttribute("data-sidebar-size") == "sm" ?
          document.body.setAttribute("data-sidebar-size", "lg") :
          document.body.setAttribute("data-sidebar-size", "sm")

          document.body.getAttribute("data-sidebar-size") == "sm" ?
          localStorage.setItem("argusblack.menu.data-sidebar-size", "lg") :
          localStorage.setItem("argusblack.menu.data-sidebar-size", "sm")
        }
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable")
        })
        document.body.classList.remove("vertical-collpsed")
      }
      this.isMenuCondensed = !this.isMenuCondensed
    },
    toggleRightSidebar() {
      setTimeout(() => {
        document.body.classList.toggle("right-bar-enabled")
      }, 15)
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled")
    }
  }
}
</script>

<template>
  <div class="card bg-warning text-white" v-show="visible">
    <div class="card-body text-center">
      <a href="#" onclick="return false;" class="bx-pull-right" @click="cerrar()">
        <i class="mdi mdi-close text-dark"></i>
      </a>
      <p class="card-text">
        Su suscripción ha expirado. Para continuar disfrutando de <strong>ArgusBlack</strong>, por favor pague
        su mensualidad y visite la sección <a href="/sistema/cuenta/" style="color: red;">CUENTA</a> para 
        renovarla ahora mismo.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: true
    }
  },
  created() {},
  methods: {
    cerrar() {
      this.visible = false
    }
  }

}
</script>